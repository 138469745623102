// @ts-ignore
import Cookies from 'js-cookie';

let SESSION_SESSION_ID: string = 'session_id';

let SESSION_USERNAME: string = 'username';

let Avatar: string = 'avatar';

let User: string = 'user';

let TOKEN_KEY: string = 'token';

let CAPTCHA_ID: string = 'captchaId';

const SessionService = {
  getCaptchaId: function() {
    return Cookies.get(CAPTCHA_ID);
  },

  setCaptchaId: function(captchaId: string | undefined) {
    return Cookies.set(CAPTCHA_ID, captchaId);
  },

  getPrefix() {
    return `${window.location.host}/`;
  },

  setUsername: function(username: string) {
    return Cookies.set(SESSION_USERNAME, username);
  },

  getUsername: function() {
    return Cookies.get(SESSION_USERNAME);
  },

  setSessionId: function(sessionId: string | number) {
    return Cookies.set(SESSION_SESSION_ID, sessionId);
  },

  getSessionId: function() {
    return Cookies.get(SESSION_SESSION_ID);
  },

  setAvatar: function(avatar: string) {
    return Cookies.set(Avatar, avatar);
  },

  getAvatar: function() {
    return Cookies.get(Avatar);
  },

  setUser: function(user: string) {
    return Cookies.set(User, user);
  },

  getUser: function() {
    return Cookies.get(User);
  },

  setToken: function(tokenKey: string | number) {
    return Cookies.set(TOKEN_KEY, tokenKey);
  },

  getToken: function() {
    return Cookies.get(TOKEN_KEY);
  },

  clear: function() {
    Cookies.remove(User);
    Cookies.remove(SESSION_USERNAME);
    Cookies.remove(SESSION_SESSION_ID);
    Cookies.remove(TOKEN_KEY);
    return Cookies.remove(Avatar);
  },
};
export default SessionService;
