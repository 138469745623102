import React, {useEffect, useState} from 'react';
import classNames from './index.module.css';
import { Space, Button } from 'antd';
import { connect } from 'react-redux';
import {showLogin} from '@/actions/login';
import SessionService from '@/ok_package/libs/session';

const greetings = {
  morning: {hello: '早上好',},
  noon: {hello: '中午好',},
  afternoon: {hello: '下午好',},
  night: {hello: '晚上好',},
  midNight: {hello: '已经到凌晨了',}
}

interface IProps {
  username: string;
  toggleLoginModal: (...args: any[]) => any;
}

function HeaderNavBar(props: IProps) {
  const {username, toggleLoginModal} = props;
  const [hello, setHello] = useState('');
  
  useEffect(() => {
    const hour = new Date().getHours();
    if(hour >= 6 && hour < 11) {
      setHello(greetings.morning.hello);
    } else if (hour >= 11 && hour < 13) {
      setHello(greetings.noon.hello);
    } else if (hour >= 13 && hour < 19) {
      setHello(greetings.afternoon.hello);
    } else if ( hour >= 19 && hour < 23 ) {
      setHello(greetings.night.hello);
    } else {
      setHello(greetings.midNight.hello);
    }
  }, []);
  
  const logOut = () => {
    SessionService.setSessionId('');
    toggleLoginModal(true);
  }
  
  return (
    <div className={classNames['nav-bar']}>
      <div className={classNames['user']}>
        <Space>
          <span>{hello}</span>
          <span>{username}</span>
          <Button type={'link'} size={'small'} onClick={logOut}>退出</Button>
        </Space>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  toggleLoginModal(params: any) {
    dispatch(showLogin(params));
  },
})

export default connect(null, mapDispatchToProps)(HeaderNavBar);