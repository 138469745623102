import React, {PureComponent, useEffect, useState} from 'react';
import {Form, Input, Button, Row, Col, message} from 'antd';
import {showLogin} from '@/actions/login';
import {connect} from 'react-redux';
import SessionService from '@/ok_package/libs/session';
import LoginService from '@/services/login';

const styles = {
  bg: {
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, .5)',
    zIndex: 999,
  },
  login: {
    position: 'absolute',
    width: '500px',
    height: '350px',
    padding: '30px 20px',
    border: '1px solid black',
    left: '50%',
    top: '50%',
    marginLeft: '-280px',
    marginTop: '-215px',
    backgroundColor: '#fff',
    borderRadius: '10px',
  },
}

const layout = {
  labelCol: {span: 6},
  wrapperCol: {span: 20, offset: 2},
};

interface CaptchaComponentProps {
  value?: string;
  onChange?: (...args: any[]) => any;
  updateCaptcha: any;
}

const CaptchaComponent = (props: CaptchaComponentProps) => {
  const {value = '', onChange, updateCaptcha} = props;
  const [inputValue, setInputValue] = useState(value);
  const [captcha, setCaptcha] = useState('');
  
  const getCaptcha = async () => {
    const {data} = await LoginService.reqCaptcha();
    SessionService.setCaptchaId(data.captchaId);
    setCaptcha(data.captcha);
  }
  
  const onInputChange = (e: any) => {
    setInputValue(e.target.value);
    // @ts-ignore
    onChange(e.target.value);
  }
  
  useEffect(() => {
    getCaptcha();
  }, [updateCaptcha]);
  
  return (
    <Row>
      <Col span={13}>
        <Input
          style={{width: '100%', height: '35px'}}
          placeholder={'验证码'}
          value={inputValue}
          onChange={onInputChange}
        />
      </Col>
      <Col span={6} offset={2}>
        <img
          src={captcha}
          alt="网络延迟，请刷新重试！"
          style={{height: '35px', cursor: 'pointer'}}
          onClick={getCaptcha}
        />
      </Col>
    </Row>
  )
}

interface IProps {
  toggleLoginModal: (...args: any[]) => any;
  updateUsername: (...args: any[]) => any;
  startPolling: (...args: any[]) => any;
}

interface IState {
  updateCaptcha: boolean;
}

class Login extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    
    this.state = {
      updateCaptcha: false,
    }
  }
  
  onFinish = (values: any) => {
    const {username, password, captcha} = values;
    const {toggleLoginModal, updateUsername, startPolling} = this.props;
    const params = {
      name: username,
      password,
      captcha,
      captchaId: SessionService.getCaptchaId(),
    }
    LoginService.reqLogin(params).then((res) => {
      if (res.success) {
        SessionService.setSessionId(res.data.sessionId);
        toggleLoginModal(false);
        updateUsername(res.data.username);
        startPolling();
        return;
      }
      this.setState({
        updateCaptcha: !this.state.updateCaptcha,
      });
    })
  };
  
  onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
    message.error('请检查输入参数！');
  };
  
  componentDidCatch(error: Error, errorInfo: any) {
    console.log('error', error);
    console.log('errorInfo', errorInfo);
  }
  
  render() {
    const {updateCaptcha} = this.state;
    return (
      // @ts-ignore
      <div style={styles.bg}>
        {/*@ts-ignore*/}
        <div style={styles.login}>
          <h1 style={{marginLeft: '30px'}}>登录</h1>
          <Form
            {...layout}
            name="basic"
            initialValues={{remember: true}}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
          >
            <Form.Item
              // label="用户名"
              name="username"
              rules={[{required: true, message: '请输入用户名!'}]}
            >
              <Input placeholder={'账号'}/>
            </Form.Item>
            
            <Form.Item
              // label="密码"
              name="password"
              rules={[{required: true, message: '请输入密码!'}]}
            >
              <Input.Password placeholder={'密码'}/>
            </Form.Item>
            
            <Form.Item
              name={'captcha'}
              rules={[{required: true, message: '请输入验证码!'}]}
              wrapperCol={{span: 22, offset: 2}}
              labelCol={{offset: 4}}
            >
              <CaptchaComponent updateCaptcha={updateCaptcha}/>
            </Form.Item>
            
            <Form.Item wrapperCol={{offset: 2, span: 20}}>
              <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                登陆
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  showLoginModal: state.loginReducer.showLoginModal,
})

const mapDispatchToProps = (dispatch: any) => ({
  toggleLoginModal(params: any) {
    dispatch(showLogin(params))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Login);