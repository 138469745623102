import { createStore } from 'redux';
// import reducer from './reducer';
import reducers from '@/reducers';

const store = createStore(
  reducers,
  // @ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;