import axios from "./commonAxios";

export default {
  reqLogin: (params?: any) => axios.post({
    api: {
      name: 'passport.user.login',
      version: '1.0'
    },
    params
  }),
  reqCaptcha: (params?: any) => axios.post({
    api: {
      name: 'passport.captcha.get',
      version: '1.0'
    },
    params
  }),
  reqUsername: (params?: any) => axios.post({
    api: {
      name: 'passport.user.accountDetail',
      version: '1.0'
    },
    params
  })
}
