// saas_gw_manager_fe
export default {
  /**
   * @description 配置显示在浏览器标签的title
   */
  title: 'code generator',
  /**
   * @description api请求基础路径
   */
  baseUrl: {
    dev: 'http://localhost:8123/?',
    pro: 'http://localhost:8123/?',
  },
  openapiUrl: {
    dev: 'http://localhost:8123/?',
    pro: 'http://localhost:8123/?',
  },
  accountUrl: {
    dev: 'https://passport-api.swallowsoft.com/?',
    pro: 'https://passport-api.swallowsoft.com/?',
  },
  // cookie的过期时间
  cookieExpires: 1,
};
// https://code-api.swallowsoft.com
// http://192.168.124.54:3344
// 192.168.124.50：8888
// https://apidesigner-api.swallowsoft.com/
