export default {
  generateRandom() {
    const memo: {[key: string]: any} = {};
    return () => {
      let v = Math.floor(Math.random() * 10000);
      if(memo[v]) {
        return memo[v];
      }
      return memo[Math.floor(Math.random() * 10000)] = Math.floor(Math.random() * 10000);
    }
  },
  memoize(fn: (...args:any[]) => any) {
    const cache: {[key: string]: any} = {};
    return function (key: string) {
      if(!cache[key]) {
        // @ts-ignore
        cache[key] = fn.apply(this, arguments);
      }
      return cache[key];
    }
  },
  compose(...fnArr: Array<(...args: any[]) => any>) {
    return function (initialValue: any) {
      return fnArr.reduceRight((res, cb) => {
        return cb(res);
      }, initialValue);
    }
  }
}