import React from "react";

const Instructor = () => {
  return (
    <div className={'header'}>
      <h1>Swallow Generator</h1>
      <ul>
        <li>快速的搭建出可运行的产品原型演示</li>
        <li>快速的搭建出一套完整的前后端代码</li>
        <li>不限制前后端语言，模板可定制</li>
      </ul>
      <p>输入建表SQL，多条SQL用英文分号;分隔</p>
    </div>
  );
};

export default Instructor;
