import React from 'react';
import DecoratedByDrawer from '@/components/decoratedByDrawer';
import {Alert, Button, Col, Form, Radio, Row, Select} from 'antd';

const {Option} = Select;

interface IProps {
  keysArr: any[];
  submitCb: (...args: any[]) => any;
  closeDrawer: (...args: any[]) => any;
  visible: boolean;
  initialValues: any;
}

export default function ForeignKeysDrawer(props: IProps) {
  const {keysArr, submitCb, closeDrawer, visible, initialValues} = props;
  
  return (
    <DecoratedByDrawer
      title={'搜索外键字段'}
      visible={visible}
      closeDrawer={closeDrawer}
    >
      {
        () => (
          <>
            <Alert message={'请选择下列外键字段'} type={'success'}/>
            <Form
              labelCol={{span: 10}}
              wrapperCol={{span: 14}}
              initialValues={initialValues}
              onFinish={submitCb}
            >
              {
                Array.isArray(keysArr) && keysArr.map((d) => {
                  return (
                    <div key={`${d.tableName}-${Math.floor(Math.random() * 10000)}`}>
                      <h1>{d.tableName}</h1>
                      {
                        d.foreignKeys.map((i: any) => {
                          return (
                            <Row key={`${i.referenceTable}-${i.foreignKey}`}>
                              <Col span={18}>
                                <Form.Item
                                  label={`${i.referenceTable}-${i.foreignKey}`}
                                  name={`${d.tableName}-${i.referenceTable}-${i.foreignKey}`}
                                >
                                  <Select>
                                    {
                                      i.referenceColumns && i.referenceColumns.map((j: any) => (
                                        <Option key={`${j}-${Math.floor(Math.random() * 10000)}`} value={j}>{j}</Option>
                                      ))
                                    }
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  name={`${d.tableName}-${i.referenceTable}-${i.foreignKey}-type`}
                                >
                                  <Radio.Group>
                                    <Radio value={'true'}>1对1</Radio>
                                    <Radio value={'false'}>1对多</Radio>
                                  </Radio.Group>
                                </Form.Item>
                              </Col>
                            </Row>
                          );
                        })
                      }
                    </div>
                  );
                })
              }
              <Row>
                <Col span={14} offset={6}>
                  <Button type={'primary'} htmlType={'submit'} children={'生成'} style={{width: '100%'}}/>
                </Col>
              </Row>
            </Form>
          </>
        )
      }
    </DecoratedByDrawer>
  );
}