import React from 'react';
import {Card, Collapse, Drawer} from 'antd';
import styles from '../index.module.css';

const {Meta} = Card;
const {Panel} = Collapse;

interface IProps {
  visible: boolean;
  hideDrawer: (...args: any[]) => any;
  sqls: any[];
  setHistorySql: (sqlContent: string) => void;
}

export default function ReqDrawer(props: IProps) {
  const {visible, hideDrawer, sqls, setHistorySql} = props;
  
  const dataFilter = (data: any[]) => {
    const types: { [key: string]: any[] } = {};
    data.forEach((d) => {
      if (!types[d.sqlTitle]) {
        types[d.sqlTitle] = [];
      }
      types[d.sqlTitle].unshift(d);
    });
    return types;
  };
  
  const _sqls = dataFilter(sqls);
  
  return (
    <Drawer
      title="获取建表语句"
      placement="right"
      width={'70%'}
      closable={false}
      onClose={hideDrawer}
      visible={visible}
    >
      <Collapse accordion>
        {
          Object.keys(_sqls).map((title) => {
            return (
              <Panel header={title} key={`panel-${title}`}>
                {
                  _sqls[title].map((s) => (
                    <Card
                      hoverable
                      title={s.sqlTitle}
                      key={s.id}
                      style={{margin: '20px 0'}}
                      onClick={() => setHistorySql(s.sqlContent)}
                    >
                      <Meta
                        title={`${new Date(s.created).toLocaleString()}`}
                      />
                      <p className={styles['sql-content']}>{s.sqlContent}</p>
                    </Card>
                  ))
                }
              </Panel>
            );
          })
        }
      </Collapse>
    </Drawer>
  );
}