import React from 'react';
import {Button, Checkbox, Col, Form, Row} from 'antd';
import DecoratedByDrawer from '@/components/decoratedByDrawer';

const layout = {
  labelCol: {span: 3},
  wrapperCol: {span: 21},
};

interface IProps {
  visible: boolean;
  closeDrawer: (...args: any[]) => any;
  keysArr: any[];
  onFinish: (...args: any[]) => any;
  initialValues: any;
}

export default function PagesConfigurations(props: IProps) {
  const {visible, closeDrawer, keysArr, onFinish, initialValues} = props;
  
  return (
    <DecoratedByDrawer
      title={'页面配置'}
      visible={visible}
      closeDrawer={closeDrawer}
    >
      {
        () => (
          <Form
            {...layout}
            name={'pagesConfigurations'}
            onFinish={onFinish}
            initialValues={initialValues}
          >
            {
              keysArr.map((tableName) => (
                <div key={tableName}>
                  <h2>{tableName}</h2>
                  <Form.Item
                    name={`${tableName}-btnConfig`}
                    label={'页面按钮'}
                  >
                    <Checkbox.Group>
                      <Row>
                        <Col span={6}>
                          <Checkbox
                            name={`${tableName}-createBtn`}
                            value={`${tableName}-createBtn`}
                          >增</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox
                            name={`${tableName}-deleteBtn`}
                            value={`${tableName}-deleteBtn`}
                          >删</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox
                            name={`${tableName}-editBtn`}
                            value={`${tableName}-editBtn`}
                          >改</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox
                            name={`${tableName}-searchBar`}
                            value={`${tableName}-searchBar`}
                          >查</Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                </div>
              ))
            }
            <Form.Item>
              <Button type={'primary'} htmlType={'submit'}>表单配置</Button>
            </Form.Item>
          </Form>
        )
      }
    </DecoratedByDrawer>
  );
}