import axios from './sqlServiceAxios';

export default {
  addSql: (params?: any) => axios.post({
    api: {
      name: 'save-sql',
      version: '1.0',
    },
    params,
  }),
  getSql: (params?: any) => axios.post({
    api: {
      name: 'list-sql-titles',
      version: '1.0',
    },
    params,
  }),
  deleteSql: (params?: any) => axios.post({
    api: {
      name: 'delete-sql',
      version: '1.0',
    },
    params,
  }),
};
