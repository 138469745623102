import baseAxios from './baseAxios';
// @ts-ignore
import Fingerprint from 'fingerprintjs';
import SessionService from '@/ok_package/libs/session';
import obfuscated from '@/ok_package/libs/obfuscated';
import qs from 'qs';

class sqlServiceApiRequest extends baseAxios {
  
  $_ok_getOptions(data: any) {
    // 生成签名
    const signKey = 'OA-Sign';
    const paramsJson = JSON.stringify(data.params);
    const deviceId = new Fingerprint({
      canvas: true,
    }).get();
    const sessionId = `${SessionService.getSessionId()}`;
    const timestamp = Date.now() * 1000 + new Date().getMilliseconds();
    if (Object.prototype.toString.call(data.params) === '[object Object]') {
      sessionStorage.setItem(
        signKey,
        deviceId + sessionId + data.api.name + data.api.version + paramsJson + timestamp,
      );
    } else {
      sessionStorage.setItem(
        signKey,
        deviceId + sessionId + data.api.name + data.api.version + timestamp,
      );
    }
    obfuscated.calcHash();
    const sign = sessionStorage.getItem(signKey);
    
    if (!data.hasOwnProperty('callType')) {
      data.callType = 'post';
    }
    // axios 配置
    const axiosOptions = {
      method: data.callType,
      url: this.baseUrl + data.api.name.trim(),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'OA-Session-Id': 'C7950076023B3D5E269D13395377AB15',
        'OA-App-Key': this.appKey,
        'OA-App-Market-ID': this.appMarketId,
        'OA-App-Version': this.appVersion,
        'OA-Device-Id': deviceId,
        'OA-Sign': sign,
      },
      data: {
        api: data.api.name,
        version: data.api.version,
        timestamp,
        params: paramsJson,
      },
      // 修改请求数据 Using application/x-www-form-urlencoded format
      transformRequest(requestData: any) {
        return qs.stringify(requestData);
      },
      timeout: 10000,
    };
    return axiosOptions;
  }
}

export default sqlServiceApiRequest