import axios from 'axios';
import {notification} from 'antd';
// @ts-ignore
import Fingerprint from 'fingerprintjs';
import SessionService from '../ok_package/libs/session';
import obfuscated from '../ok_package/libs/obfuscated';
// @ts-ignore
import qs from 'qs';

axios.interceptors.response.use(
  response => {
    if (response.status >= 200 && response.status < 300) {
      const {data} = response;
      // 返回失败
      if (data && !data.success) {
        notification.error({
          message: 'Api Return Error',
          description: data.errorMessage,
        });
      }
    } else {
      notification.error({
        message: `Status Code ${response.status}`,
        description: JSON.stringify(response.data),
      });
    }
    return response;
  },
  error => {
    notification.error({
      message: error.message,
    });
  },
);

class newApiRequest {
  public baseUrl: string;
  public httpMethod: string;
  public appKey: number;
  public appMarketId: number;
  public appVersion: string;
  
  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
    this.httpMethod = 'post';
    this.appKey = 1001520;
    this.appMarketId = 678;
    this.appVersion = '1.0';
  }
  
  get(data: any) {
    this.httpMethod = 'get';
    return this.callApi(data);
  }
  
  post(data: any) {
    this.httpMethod = 'post';
    return this.callApi(data);
  }
  
  callApi(obj: any, httpDefaultOpts?: any) {
    if (!httpDefaultOpts) {
      httpDefaultOpts = this.$_ok_getOptions(obj);
    }
    return axios(httpDefaultOpts).then(response => {
      if (!response.data) {
        const errorCode = 'protocol error';
        return Promise.reject(errorCode);
      }
      if (response.data.hasOwnProperty('success') && response.data.success) {
        return Promise.resolve(response.data);
      }
      if (response.data.hasOwnProperty('errorCode') && response.data.errorCode) {
        const i18nNamespace = obj.api.name.split('.')[0];
        const errorCode = `${i18nNamespace}.message.apiError.${response.data.errorCode}`;
        return Promise.resolve(errorCode);
      }
      
      // 服务端响应内容不符合 okapi 协议规范
      return Promise.reject(new Error('protocol error'));
    });
  }
  
  $_ok_getOptions(data: any) {
    // 生成签名
    const signKey = 'OA-Sign';
    const paramsJson = JSON.stringify(data.params);
    const deviceId = new Fingerprint({
      canvas: true,
    }).get();
    const sessionId = `${SessionService.getSessionId()}`;
    const timestamp = Date.now() * 1000 + new Date().getMilliseconds();
    if (Object.prototype.toString.call(data.params) === '[object Object]') {
      sessionStorage.setItem(
        signKey,
        deviceId + sessionId + data.api.name + data.api.version + paramsJson + timestamp,
      );
    } else {
      sessionStorage.setItem(
        signKey,
        deviceId + sessionId + data.api.name + data.api.version + timestamp,
      );
    }
    obfuscated.calcHash();
    const sign = sessionStorage.getItem(signKey);
    
    if (!data.hasOwnProperty('callType')) {
      data.callType = 'post';
    }
    // axios 配置
    const axiosOptions = {
      method: data.callType,
      url: this.baseUrl + data.api.name.trim(),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'OA-Session-Id': sessionId,
        'OA-App-Key': this.appKey,
        'OA-App-Market-ID': this.appMarketId,
        'OA-App-Version': this.appVersion,
        'OA-Device-Id': deviceId,
        'OA-Sign': sign,
      },
      data: {
        api: data.api.name,
        version: data.api.version,
        timestamp,
        params: paramsJson,
      },
      // 修改请求数据 Using application/x-www-form-urlencoded format
      transformRequest(requestData: any) {
        return qs.stringify(requestData);
      },
      timeout: 10000,
    };
    return axiosOptions;
  }
}

export default newApiRequest;