import React, {PureComponent} from 'react';
import Instructor from '@/components/Instructor';
import CodeMirror from '@uiw/react-codemirror';
import 'codemirror/keymap/sublime';
import {Button, Col, Input, Row, Space} from 'antd';
import {connect} from 'react-redux';
import {showLogin} from '@/actions/login';
import ForeignKeysDrawer from './base/ForeignKeysDrawer';
import Login from '@/components/login';
import LoginService from '@/services/login';
import HeaderNavBar from '@/components/headerNavBar';
import Tools from '@/pages/home/base/Tools';
import styles from './index.module.css';
import PagesConfigurations from '@/pages/home/base/PagesConfigurations';
import FormConfigDrawer from '@/pages/home/base/FormConfigDrawer';

interface IProps {
  toggleLoginModal: (...args: any[]) => any;
  showLoginModal: boolean;
}

interface IState {
  sqlContent: string;
  product: string;
  username: string;
  foreignKeys: any[];
  tables: any[];
  defaultValues: any;
  showDrawer: boolean;
  showPagesConfigurations: boolean;
  pagesConfigurations: any;
  formConfigDrawerVisible: boolean;
  partialInfo: {};
}

class Home extends PureComponent<IProps, IState> {
  public timer: any;
  
  constructor(props: Readonly<IProps>) {
    super(props);
    
    this.state = {
      sqlContent: '\n\n\n',
      product: '',
      username: '',
      foreignKeys: [],
      tables: [],
      defaultValues: {},
      showDrawer: false,
      showPagesConfigurations: false,
      pagesConfigurations: {},
      formConfigDrawerVisible: false,
      partialInfo: {},
    };
  }
  
  componentWillUnmount() {
    clearTimeout(this.timer);
  }
  
  componentDidMount() {
    this.getUsername();
  }
  
  /* 登陆相关 */
  polling = () => {
    this.timer = setInterval(async () => {
      const {toggleLoginModal} = this.props;
      const res = await LoginService.reqUsername();
      if (!res.success) {
        clearTimeout(this.timer);
        toggleLoginModal(true);
      }
    }, 60 * 1000);
  };
  
  getUsername = async () => {
    const {toggleLoginModal} = this.props;
    const res = await LoginService.reqUsername();
    // console.log(res);
    if (!res.success) {
      toggleLoginModal(true);
      return;
    }
    this.setState({
      username: res.data.username,
    });
  };
  
  updateUsername = (username: string) => {
    if (username) {
      this.setState({
        username,
      });
    }
  };
  
  /* 创建 API */
  addApi = () => {
    const {sqlContent, product} = this.state;
    let formData = new FormData();
    formData.append('content', sqlContent);
    formData.append('product', product);
    fetch('https://code-api.swallowsoft.com/api/apiAdd', {
      method: 'POST',
      body: formData,
      mode: 'no-cors',
      headers: {
        'Access-Control-Allow-Origin': 'https://code-api.swallowsoft.com',
      },
    }).then((resp) => resp.blob());
    this.setState({
      product: '',
    });
  };
  
  /* 检测建表语句 */
  handleTestSql = () => {
    const {sqlContent} = this.state;
    const formData = new FormData();
    formData.append('content', sqlContent);
    
    const getTables = () => {
      fetch('https://code-api.swallowsoft.com/api/return-Tables', {
        method: 'POST',
        body: formData,
        headers: {
          'Access-Control-Allow-Origin': 'https://code-api.swallowsoft.com',
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            tables: data,
          });
        });
    };
    const detectForeignKey = () => {
      fetch('https://code-api.swallowsoft.com/api/detect-foreignKey', {
        method: 'POST',
        body: formData,
        headers: {
          'Access-Control-Allow-Origin': 'https://code-api.swallowsoft.com',
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.getDefaultValues(data);
          this.setState({
            foreignKeys: data,
            showPagesConfigurations: true,
          });
        });
    };
    Promise.all([getTables(), detectForeignKey()]);
  };
  
  /* 页面的增删改查配置 */
  togglePagesConfigurations = () => {
    this.setState({
      showPagesConfigurations: !this.state.showPagesConfigurations,
    });
  };
  
  initValues = (keysArr: Array<string>) => {
    // @ts-ignore
    if (!(Array.isArray(keysArr) || keysArr.length)) return;
    const res: { [key: string]: any } = {};
    keysArr.forEach((tableName: string) => {
      res[`${tableName}-btnConfig`] = [
        tableName + '-createBtn',
        tableName + '-deleteBtn',
        tableName + '-editBtn',
        tableName + '-searchBar',
      ];
    });
    return res;
  };
  
  getPagesConfigurations = (values: any) => {
    const res: { [key: string]: any } = {};
    let _key = '';
    Object.keys(values).forEach((key) => {
      _key = key.split('-')[0];
      res[_key] = {};
      if (values[key]) {
        values[key].forEach((v: string) => {
          res[_key][v.split('-')[1]] = 1;
        });
      }
    });
    
    this.setState({
      showPagesConfigurations: false,
      formConfigDrawerVisible: true,
      pagesConfigurations: res,
    });
  };
  
  /* 页面中展示表格、创建表单、编辑表单中的配置 */
  toggleFormConfig = () => {
    this.setState({
      formConfigDrawerVisible: !this.state.formConfigDrawerVisible,
    });
  };
  
  getFormConfig = (values: any) => {
    // console.log(values);
    
    this.setState({
      formConfigDrawerVisible: false,
      partialInfo: values,
      // 外键配置抽屉
      showDrawer: true,
    });
  };
  
  /* 外键配置 */
  closeForeignKeysDrawer = () => {
    this.setState({
      showDrawer: false,
    });
  };
  
  getDefaultValues = (arr: any[]) => {
    const defaultValues: { [key: string]: any } = {};
    arr.forEach((item) => {
      item.foreignKeys.forEach((d: any) => {
        if (d.referenceColumns) {
          defaultValues[`${item.tableName}-${d.referenceTable}-${d.foreignKey}`] = d.referenceColumns[0];
          defaultValues[`${item.tableName}-${d.referenceTable}-${d.foreignKey}-type`] = 'true';
        }
      });
    });
    this.setState({
      defaultValues,
    });
  };
  // 处理向后台发送关于用户选择的外键数据
  decorateForeignKeys = (obj: { [key: string]: any }) => {
    const res: { [key: string]: any } = {};
    let temp = [];
    Object.keys(obj).forEach((k: string) => {
      temp = k.split('-');
      if (!res[temp[0]]) {
        res[temp[0]] = {};
      }
      if (temp.includes('type')) {
        res[temp[0]][`${temp[1]}-${temp[2]}-type`] = obj[k];
        return;
      }
      res[temp[0]][`${temp[1]}-${temp[2]}`] = obj[k];
    });
    return res;
  };
  
  /* 工具函数 */
  setHistorySql = (sqlContent: string) => {
    this.setState({sqlContent});
  };
  
  /* 生成代码 */
  confirmGeneration = (values: any) => {
    // 提交表单的数据应该放在用户选择完每个页面对应的增删改查后提交
    const {sqlContent, pagesConfigurations, partialInfo} = this.state;
    const formData = new FormData();
    const _values = this.decorateForeignKeys(values);
    formData.append('params', JSON.stringify(_values));
    formData.append('config', JSON.stringify(pagesConfigurations));
    formData.append('partial', JSON.stringify(partialInfo));
    formData.append('content', sqlContent);
    
    fetch('https://code-api.swallowsoft.com/api/form-submit-url', {
      method: 'POST',
      body: formData,
      headers: {
        'Access-Control-Allow-Origin': 'https://code-api.swallowsoft.com',
      },
    })
      .then((resp) => {
        return resp.blob();
      })
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'demo.zip';
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
    this.setState({
      showDrawer: false,
    });
  };
  
  componentDidCatch(error: Error, errorInfo: any) {
    console.log(error, errorInfo);
  }
  
  render() {
    const {
      product,
      showDrawer,
      foreignKeys,
      username,
      defaultValues,
      sqlContent,
      showPagesConfigurations,
      tables,
      formConfigDrawerVisible,
    } = this.state;
    const {showLoginModal} = this.props;
    
    const initialValues = this.initValues(tables);
    
    return (
      <>
        {/*登录框*/}
        {
          showLoginModal ? (<Login updateUsername={this.updateUsername} startPolling={this.polling}/>) : null
        }
        
        {/*顶部导航*/}
        <HeaderNavBar username={username}/>
        
        {/*标题和指引*/}
        <Instructor/>
        
        {/*建表语句填写区域*/}
        <div className={styles['code-container']}>
          <CodeMirror
            value={sqlContent}
            options={{
              theme: 'default',
              keyMap: 'sublime',
              mode: 'mysql',
              lineNumbers: true,
            }}
            onChange={(editor) => {
              this.setState({sqlContent: editor.getValue()});
            }}
          />
        </div>
        
        {/*操作区域*/}
        <div className="actions">
          <Row>
            <Space>
              <Button type={'primary'} children={'检测'} onClick={this.handleTestSql}/>
            </Space>
          </Row>
          <Row style={{marginTop: '20px'}}>
            <Col span={5}>
              <Input placeholder={'请在创建 api 前输入产品名'} value={product}
                     onChange={(e) => this.setState({product: e.target.value})}/>
            </Col>
            <Col span={4} offset={1}>
              <Button type={'primary'} children={'创建API'} onClick={this.addApi}/>
            </Col>
          </Row>
        </div>
        
        {/*建表语句的获取、添加和删除区域*/}
        <Tools
          username={username}
          sqlContent={sqlContent}
          setHistorySql={this.setHistorySql}
        />
        
        {/*页面增删改查配置抽屉*/}
        <PagesConfigurations
          visible={showPagesConfigurations}
          closeDrawer={this.togglePagesConfigurations}
          keysArr={tables}
          initialValues={initialValues}
          onFinish={this.getPagesConfigurations}
        />
        
        {/*页面表单配置抽屉*/}
        <FormConfigDrawer
          visible={formConfigDrawerVisible}
          sqlContent={sqlContent}
          closeDrawer={this.toggleFormConfig}
          onFinish={this.getFormConfig}
        />
        
        {/*外键字段选择抽屉*/}
        <ForeignKeysDrawer
          closeDrawer={this.closeForeignKeysDrawer}
          visible={showDrawer}
          keysArr={foreignKeys}
          initialValues={defaultValues}
          submitCb={this.confirmGeneration}
        />
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  showLoginModal: state.loginReducer.showLoginModal,
});

const mapDispatchToProps = (dispatch: any) => ({
  toggleLoginModal(params: any) {
    dispatch(showLogin(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);