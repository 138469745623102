import React, {useState} from 'react';
import {Card, Collapse, Drawer, message, Modal} from 'antd';
import {CloseOutlined} from '@ant-design/icons';
import styles from '@/pages/home/index.module.css';
import sqlService from '@/services/sqlService';

const {Meta} = Card;
const {Panel} = Collapse;

interface IProps {
  visible: boolean;
  closeDrawer: (...args: any[]) => any;
  sqls: any[];
  refreshData: () => void;
}

export default function DeletionDrawer(props: IProps) {
  const {visible, closeDrawer, sqls, refreshData} = props;
  const [modalVisible, setModalVisible] = useState(false);
  const [deletionInfo, setDeletionInfo] = useState({
    orgId: -1,
    sqlTitle: '',
  });
  
  const toggleModal = (deletionInfo: any) => {
    setDeletionInfo(deletionInfo);
    setModalVisible(!modalVisible);
  };
  
  const deleteSql = async () => {
    const {orgId, sqlTitle} = deletionInfo;
    const res = await sqlService.deleteSql({
      orgId,
      sqlTitle,
    });
    if (res.success) {
      message.success('删除成功！');
      refreshData();
    } else {
      message.error('发生未知错误，请稍后再试！');
    }
    setModalVisible(false);
  };
  
  const dataFilter = (data: any[]) => {
    const types: { [key: string]: any[] } = {};
    data.forEach((d) => {
      if (!types[d.sqlTitle]) {
        types[d.sqlTitle] = [];
      }
      types[d.sqlTitle].unshift(d);
    });
    return types;
  };
  
  const _sqls = dataFilter(sqls);
  
  return (
    <>
      <Drawer
        title={'删除抽屉'}
        placement={'right'}
        width={'70%'}
        closable={false}
        visible={visible}
        onClose={closeDrawer}
      >
        <Collapse accordion>
          {
            Object.keys(_sqls).map((title) => {
              return (
                <Panel header={title} key={`panel-${title}`}>
                  {
                    _sqls[title].map((s) => (
                      <Card
                        hoverable
                        title={s.sqlTitle}
                        key={s.id}
                        style={{margin: '20px 0'}}
                        extra={<CloseOutlined/>}
                        onClick={() => toggleModal(s)}
                      >
                        <Meta
                          title={`${new Date(s.created).toLocaleString()}`}
                        />
                        <p className={styles['sql-content']}>{s.sqlContent}</p>
                      </Card>
                    ))
                  }
                </Panel>
              );
            })
          }
        </Collapse>
      </Drawer>
      
      <Modal
        title="删除"
        visible={modalVisible}
        okText={'删除'}
        cancelText={'取消'}
        onOk={deleteSql}
        onCancel={toggleModal}
      >
        <p>确定删除吗？</p>
      </Modal>
    </>
  );
}
