import React, {useState} from 'react';
import styles from '../index.module.css';
import {CloseCircleFilled, FolderFilled, PlusCircleFilled} from '@ant-design/icons';
import ReqDrawer from '@/pages/home/base/ReqDrawer';
import AddingModal from '@/pages/home/base/AddingModal';
import DeletionDrawer from '@/pages/home/base/DeletionDrawer';
import sqlService from '@/services/sqlService';
import {message} from 'antd';

interface IProps {
  username: string;
  sqlContent: string;
  setHistorySql: (sqlContent: string) => void;
}

export default function Tools(props: IProps) {
  const {username, sqlContent, setHistorySql} = props;
  const [reqDrawerVisible, setReqDrawerVisible] = useState(false);
  const [addingModalVisible, setAddingModalVisible] = useState(false);
  const [deletionDrawerVisible, setDeletionDrawerVisible] = useState(false);
  const [sqls, setSqls] = useState([]);
  
  // 请求建表语句
  const reqSqls = async () => {
    const res = await sqlService.getSql({orgId: 1});
    if (res.success) {
      setSqls(res.data);
    } else {
      message.error('数据请求错误！');
    }
  };
  
  const toggleReqDrawer = async () => {
    // false -> true  打开抽屉
    if (!reqDrawerVisible) {
      reqSqls();
    }
    setReqDrawerVisible(!reqDrawerVisible);
  };
  
  const getHistorySql = (sqlContent: string) => {
    setHistorySql(sqlContent);
    setReqDrawerVisible(false);
  };
  
  // 添加建表语句
  const toggleAddingModal = () => {
    setAddingModalVisible(!addingModalVisible);
  };
  const addSql = async (name: string) => {
    // 此处应向后台发送建表语句的名称和内容
    // 可以从父级获取当前操作用户
    const res = await sqlService.addSql({
      sqlTitle: name,
      sqlContent,
      orgId: 1,
      version: 1,
    });
    
    if (res.success) {
      message.success('保存成功！');
    } else {
      message.error('添加失败，请稍后再试！');
    }
    setAddingModalVisible(false);
  };
  
  // 删除建表语句
  const toggleDeletionDrawer = () => {
    if (!deletionDrawerVisible) {
      reqSqls();
    }
    setDeletionDrawerVisible(!deletionDrawerVisible);
  };
  
  return (
    <>
      {/*container*/}
      <div className={styles.tools}>
        <FolderFilled onClick={toggleReqDrawer}/>
        <PlusCircleFilled onClick={toggleAddingModal}/>
        <CloseCircleFilled onClick={toggleDeletionDrawer}/>
      </div>
      
      {/*获取建表语句的抽屉*/}
      <ReqDrawer
        visible={reqDrawerVisible}
        hideDrawer={toggleReqDrawer}
        sqls={sqls}
        setHistorySql={getHistorySql}
      />
      
      {/*添加语句的弹窗*/}
      <AddingModal
        visible={addingModalVisible}
        okCb={addSql}
        cancelCb={toggleAddingModal}
      />
      
      {/*删除建表语句的抽屉*/}
      <DeletionDrawer
        visible={deletionDrawerVisible}
        closeDrawer={toggleDeletionDrawer}
        sqls={sqls}
        refreshData={reqSqls}
      />
    </>
  );
}