import React from 'react';
import { Route } from 'react-router-dom';
import Home from './pages/home';
import { I18nProvider, LOCALES } from '@/i18n';
import { connect } from 'react-redux';

interface IProps {
  language?: string;
}
function App(props: IProps) {
  const {language} = props;
  return (
    <I18nProvider locale={(language === 'en') ? LOCALES.ENGLISH : LOCALES.CHINESE}>
      <div className="App">
        <Route path={'/'} component={Home}/>
      </div>
    </I18nProvider>
  );
}

const mapStateToProps = (state: any) => ({
  language: state.language,
})

export default connect(mapStateToProps, null)(App);
