import * as types from '@/actions/mutation-types';

const initialState = {
  language: 'en',
};

const mutations: {[key: string]: any} = {
  [types.TOGGLE_LANGUAGE](state: any, action: any) {
    return {
      ...state,
      language: action.payload,
    };
  }
}

export default function (state = initialState, action: any) {
  if(!mutations[action.type]) return state;
  return mutations[action.type](state, action);
}