import React, {PureComponent} from 'react';
import {Button, Checkbox, Col, Form, message, Row} from 'antd';
import DecoratedByDrawer from '@/components/decoratedByDrawer';
import utils from '@/utils';

const FormItem = Form.Item;
const CheckboxGroup = Checkbox.Group;

const forms = [
  {
    label: '表格',
    name: 'tableColumns',
  },
  {
    label: '详情',
    name: 'detail',
  },
  {
    label: '添加表单',
    name: 'creatorForm',
  },
  {
    label: '编辑表单',
    name: 'editForm',
  },
];

interface IProps {
  visible: boolean;
  sqlContent: string;
  closeDrawer: (...args: any[]) => any;
  onFinish: (...args: any[]) => any;
}

interface IState {
  baseInfo: { [key: string]: any };
}

export default class FormConfigDrawer extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    
    this.state = {
      baseInfo: {},
    };
  }
  
  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    if (this.props.sqlContent !== prevProps.sqlContent) {
      this.withdrawInfo(this.props.sqlContent);
    }
  }
  
  withdrawInfo = (sql: string) => {
    const filter1 = (str: string) => str.split('DROP TABLE IF EXISTS');
    const filter2 = (strArr: string[]) => {
      const resArr: string[] = [];
      let temp: any;
      strArr.forEach((s: string) => {
        temp = s.match(/(?=CREATE TABLE)(.|\n)+(?=PRIMARY)/);
        // console.log('temp', temp);
        if (temp && temp[0]) {
          resArr.push(temp[0]);
        }
      });
      return resArr;
    };
    const filter3 = (strArr: string[]) => {
      let temp: any;
      const res: { [key: string]: any } = {};
      let tableName: string[] = [];
      try {
        strArr.forEach((s: string) => {
          temp = s.match(/`\w+`/g);
          temp = temp.map((s: string) => {
            return s.slice(1, s.length - 1);
          });
          tableName = temp.splice(0, 1);
          temp.forEach((s: string) => {
            if (!res[tableName[0]]) {
              res[tableName[0]] = {};
            }
            res[tableName[0]][s] = s;
          });
        });
      } catch (e) {
        message.error('当前 sql 语句不合法！');
        return [];
      }
      return res;
    };
    const filter = utils.compose(filter3, filter2, filter1);
    this.setState({
      baseInfo: filter(sql),
    });
  };
  
  initValues = (baseInfo: any) => {
    const res: { [key: string]: any } = {};
    Object.keys(baseInfo).forEach((table: string) => {
      forms.forEach((f: { name: string, label: string }) => {
        res[`${table}-${f.name}`] = Object.keys(baseInfo[table]);
      });
    });
    return res;
  };
  
  render() {
    const {visible, closeDrawer, onFinish} = this.props;
    const {baseInfo} = this.state;
    
    return (
      <DecoratedByDrawer
        title={'页面表单配置'}
        visible={visible}
        closeDrawer={closeDrawer}
        width={'70%'}
      >
        {
          () => (
            <Form
              name={'formConfig'}
              labelCol={{span: 24}}
              wrapperCol={{span: 24}}
              layout={'vertical'}
              initialValues={this.initValues(baseInfo)}
              onFinish={onFinish}
            >
              {
                Object.keys(baseInfo).map((table: string) => {
                  
                  const options = Object.keys(baseInfo[table]).map((c: string) => (
                    <Col span={6} key={c}>
                      <Checkbox value={c}>{c}</Checkbox>
                    </Col>
                  ));
                  
                  return (
                    <div key={table}>
                      <h2>{table}</h2>
                      {
                        forms.map((f: { name: string, label: string }) => (
                          <FormItem
                            label={`${table}-${f.label}`}
                            name={`${table}-${f.name}`}
                            key={f.name}
                          >
                            <CheckboxGroup>
                              <Row>{options}</Row>
                            </CheckboxGroup>
                          </FormItem>
                        ))
                      }
                    </div>
                  );
                })
              }
              <FormItem>
                <Button type={'primary'} htmlType={'submit'}>外键检测</Button>
              </FormItem>
            </Form>
          )
        }
      </DecoratedByDrawer>
    );
  }
}