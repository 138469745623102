import React from 'react';
import { Drawer } from 'antd';

interface IProps {
  visible: boolean;
  title: string;
  children: (...args: any[]) => any;
  closeDrawer: (...args: any[]) => any;
  width?: string;
}

export default function DecoratedByDrawer(props: IProps) {
  const { visible, title, children, closeDrawer, width } = props;
  
  return (
    <Drawer
      title={title}
      placement={'right'}
      width={width ? width : '60%'}
      visible={visible}
      destroyOnClose={true}
      onClose={closeDrawer}
    >
      {
        typeof children === 'function' && children()
      }
    </Drawer>
  )
}
