import { LOCALES } from "@/i18n/locales";

export default {
  [LOCALES.ENGLISH]: {
    'hello': 'Hello',
    'name': 'name',
    'age': 'age',
    'attr': 'attr',
    'keyword': 'keyword',
    'job': 'job',
    'edit': 'edit',
    'delete': 'delete',
    'deletion': 'deletion',
    'create': 'create',
    'search': 'search',
    'actions': 'actions',
    'Please input your name': 'Please input your name',
    'Please input your age': 'Please input your age',
    'test': 'test',
    'click': 'click',
    'Confirm': 'confirm',
    'cancel': 'cancel',
    'Are you sure you want to delete the item': 'Are you sure you want to delete the item',
    'creator': 'creator',
    'detail': 'detail',
    'gender': 'gender',
    'department': 'department',
    'course': 'course',
    'stuNo': 'stuNo',
    'Are you sure to delete': 'Are you sure to delete',
    'power': 'power',
    
    
    'account':'account',
    'accountIdentity':'accountIdentity',
    'accountMessage':'accountMessage',
    'accountPermission':'accountPermission',
    'chart':'chart',
    'chartAppeal':'chartAppeal',
    'chartAppealHanding':'chartAppealHanding',
    'chartCommon':'chartCommon',
    'chartCorrect':'chartCorrect',
    'chartSensitive':'chartSensitive',
    'chartViolation':'chartViolation',
    'chartViolationPunish':'chartViolationPunish',
    'company':'company',
    'companyAnnouncement':'companyAnnouncement',
    'companyCheck':'companyCheck',
    'companyComplaint':'companyComplaint',
    'companyComplaintHandling':'companyComplaintHandling',
    'companyCooperationMedia':'companyCooperationMedia',
    'companyDepartment':'companyDepartment',
    'companyFinance':'companyFinance',
    'companyForbidden':'companyForbidden',
    'companyIntroduce':'companyIntroduce',
    'companyJob':'companyJob',
    'companyJobPermission':'companyJobPermission',
    'companyLicense':'companyLicense',
    'companyManager':'companyManager',
    'companyMeeting':'companyMeeting',
    'companyMobile':'companyMobile',
    'companyNotice':'companyNotice',
    'companyNoticeDetail':'companyNoticeDetail',
    'companyPunish':'companyPunish',
    'companyRemind':'companyRemind',
    'companyRemindDetail':'companyRemindDetail',
    'companyTeam':'companyTeam',
    'companyWebchartAllocation':'companyWebchartAllocation',
    'companyWechart':'companyWechart',
    'customer':'customer',
    'customerAllocation':'customerAllocation',
    'customerAsset':'customerAsset',
    'customerAttention':'customerAttention',
    'customerComplaint':'customerComplaint',
    'customerPayment':'customerPayment',
    'customerRefund':'customerRefund',
    'customerRiskCheck':'customerRiskCheck',
    'customerSign':'customerSign',
    'customerSignCheck':'customerSignCheck',
    'employee':'employee',
    'employeeCertificate':'employeeCertificate',
    'employeeCheck':'employeeCheck',
    'employeeComplaint':'employeeComplaint',
    'employeeComplaintHanding':'employeeComplaintHanding',
    'employeeExperience':'employeeExperience',
    'employeeForbidden':'employeeForbidden',
    'employeeIntroduce':'employeeIntroduce',
    'employeeJob':'employeeJob',
    'employeePunish':'employeePunish',
    'employeeSecurityAccount':'employeeSecurityAccount',
    'employeeTeam':'employeeTeam',
    'employeeTrain':'employeeTrain',
    'identity':'identity',
    'identityPermission':'identityPermission',
    'permission':'permission',
    'product':'product',
    'productIntroduce':'productIntroduce',
    'productReport':'productReport',
    'productSeries':'productSeries',
    'productService':'productService',
    'productServiceDetail':'productServiceDetail',
    'regulator':'regulator',
    'regulatorOrganization':'regulatorOrganization',
    'regulatorStation':'regulatorStation',
    'regulatorStationPermission':'regulatorStationPermission',
    'security':'security',
    'securityInstitution':'securityInstitution',
    
    
    'accountName':'accountName',
    'accountPassword':'accountPassword',
    'accountStatus':'accountStatus',
    'accountType':'accountType',
    'address':'address',
    'announcementContent':'announcementContent',
    'announcementFile':'announcementFile',
    'announcementTitle':'announcementTitle',
    'announcementType':'announcementType',
    'appealReason':'appealReason',
    'appealResult':'appealResult',
    'appealTitle':'appealTitle',
    'asset':'asset',
    'author':'author',
    'authorInstitution':'authorInstitution',
    'beneficiary':'beneficiary',
    'birthdate':'birthdate',
    'businessCertificate':'businessCertificate',
    'businessFile':'businessFile',
    'certificateAuthority':'certificateAuthority',
    'certificateFile':'certificateFile',
    'certificateName':'certificateName',
    'certificateNumber':'certificateNumber',
    'certifier':'certifier',
    'certifierPhone':'certifierPhone',
    'chargePerson':'chargePerson',
    'chartFile':'chartFile',
    'chartType':'chartType',
    'checkReason':'checkReason',
    'checkResult':'checkResult',
    'checkTitle':'checkTitle',
    'checkType':'checkType',
    'city':'city',
    'commonSense':'commonSense',
    'companyName':'companyName',
    'complaintAppeal':'complaintAppeal',
    'complaintContent':'complaintContent',
    'complaintEmail':'complaintEmail',
    'complaintPhone':'complaintPhone',
    'complaintStatus':'complaintStatus',
    'complaintTitle':'complaintTitle',
    'complaintWechart':'complaintWechart',
    'contractFile':'contractFile',
    'contractNumber':'contractNumber',
    'contractPeriod':'contractPeriod',
    'cooperation':'cooperation',
    'cooperationResult':'cooperationResult',
    'correctContent':'correctContent',
    'correctTitle':'correctTitle',
    'createTime':'createTime',
    'creditCode':'creditCode',
    'customerName':'customerName',
    'departmentName':'departmentName',
    'education':'education',
    'email':'email',
    'emergencyContact':'emergencyContact',
    'emergencyPhone':'emergencyPhone',
    'employeeName':'employeeName',
    'endDate':'endDate',
    'endTime':'endTime',
    'examResult':'examResult',
    'exchange':'exchange',
    'expireDay':'expireDay',
    'failReason':'failReason',
    'financeDate':'financeDate',
    'financeFile':'financeFile',
    'financeTitle':'financeTitle',
    'followUp':'followUp',
    'foundDate':'foundDate',
    'handingResult':'handingResult',
    'idCard':'idCard',
    'idFile':'idFile',
    'idPeriod':'idPeriod',
    'identityDescription':'identityDescription',
    'identityName':'identityName',
    'institutionName':'institutionName',
    'institutionType':'institutionType',
    'integrity':'integrity',
    'introduceContent':'introduceContent',
    'introduceTitle':'introduceTitle',
    'isAppeal':'isAppeal',
    'isAppealSuccess':'isAppealSuccess',
    'isCorrect':'isCorrect',
    'isEnsureReceive':'isEnsureReceive',
    'isHode':'isHode',
    'isInvoice':'isInvoice',
    'isPractice':'isPractice',
    'isReceive':'isReceive',
    'isSend':'isSend',
    'issueDate':'issueDate',
    'issuer':'issuer',
    'jobContent':'jobContent',
    'jobDescription':'jobDescription',
    'jobName':'jobName',
    'legalIdCard':'legalIdCard',
    'legalIdFile':'legalIdFile',
    'legalPerson':'legalPerson',
    'licenseFile':'licenseFile',
    'licenseNumber':'licenseNumber',
    'licensePeriod':'licensePeriod',
    'logoFile':'logoFile',
    'manager':'manager',
    'managerName':'managerName',
    'managerPhone':'managerPhone',
    'managerPosition':'managerPosition',
    'mediaName':'mediaName',
    'mediaType':'mediaType',
    'meetingAddress':'meetingAddress',
    'meetingContent':'meetingContent',
    'meetingType':'meetingType',
    'messageType':'messageType',
    'mobileModel':'mobileModel',
    'mobileStatus':'mobileStatus',
    'noticeContent':'noticeContent',
    'noticeTitle':'noticeTitle',
    'onlinePosition':'onlinePosition',
    'operationType':'operationType',
    'organizationName':'organizationName',
    'origin':'origin',
    'partakePerson':'partakePerson',
    'payment':'payment',
    'permissionApi':'permissionApi',
    'permissionDescription':'permissionDescription',
    'permissionGroup':'permissionGroup',
    'permissionName':'permissionName',
    'permissionTable':'permissionTable',
    'phone':'phone',
    'plot':'plot',
    'plotJudge':'plotJudge',
    'price':'price',
    'productName':'productName',
    'productStatus':'productStatus',
    'productType':'productType',
    'punishAccording':'punishAccording',
    'punishNumber':'punishNumber',
    'punishReason':'punishReason',
    'punishTitle':'punishTitle',
    'punishType':'punishType',
    'qq':'qq',
    'realPerson':'realPerson',
    'refund':'refund',
    'refundVoucher':'refundVoucher',
    'region':'region',
    'registerCapital':'registerCapital',
    'registerOffice':'registerOffice',
    'regulatorName':'regulatorName',
    'remark':'remark',
    'repeatDay':'repeatDay',
    'reportFile':'reportFile',
    'reportOrigin':'reportOrigin',
    'reportTitle':'reportTitle',
    'result':'result',
    'resultType':'resultType',
    'risk':'risk',
    'roleType':'roleType',
    'securityAccount':'securityAccount',
    'securityAccountStatus':'securityAccountStatus',
    'securityCode':'securityCode',
    'securityName':'securityName',
    'securityType':'securityType',
    'sendOffice':'sendOffice',
    'sensitiveMatch':'sensitiveMatch',
    'sensitiveWord':'sensitiveWord',
    'seriesName':'seriesName',
    'serviceCount':'serviceCount',
    'serviceType':'serviceType',
    'sex':'sex',
    'signType':'signType',
    'sourceTable':'sourceTable',
    'sponsored':'sponsored',
    'startDate':'startDate',
    'startTime':'startTime',
    'stationName':'stationName',
    'suggestion':'suggestion',
    'suggestionContent':'suggestionContent',
    'suggestionReason':'suggestionReason',
    'suggestionType':'suggestionType',
    'superviseOffice':'superviseOffice',
    'teamName':'teamName',
    'trainAddress':'trainAddress',
    'trainContent':'trainContent',
    'trainName':'trainName',
    'trainOrganization':'trainOrganization',
    'updateTime':'updateTime',
    'updateUser':'updateUser',
    'useAsset':'useAsset',
    'useRemark':'useRemark',
    'violationContent':'violationContent',
    'voucher':'voucher',
    'website':'website',
    'wechart':'wechart',
    'wechartStatus':'wechartStatus',
    'work':'work',
    'workUnit':'workUnit',
    'xhUrl':'xhUrl',
    
    
  }
}