import React, {useRef} from 'react';
import {Form, Input, Modal} from 'antd';

interface IProps {
  visible: boolean;
  cancelCb: (...args: any[]) => any;
  okCb: (...args: any[]) => any;
}

export default function AddingModal(props: IProps) {
  const {visible, cancelCb, okCb} = props;
  const InputRef = useRef<Input>(null);
  
  const addSql = () => {
    // 应向 okCb 传入建表名称
    // @ts-ignore
    okCb(InputRef.current.state.value);
  };
  
  return (
    <Modal
      title="添加建表语句"
      visible={visible}
      destroyOnClose={true}
      onOk={addSql}
      onCancel={cancelCb}
      okText={'添加'}
      cancelText={'取消'}
    >
      <Form onFinish={addSql}>
        <Form.Item name={'name'} rules={[{required: true, message: '请输入建表名称'}]}>
          <Input placeholder={'建表名称'} ref={InputRef}/>
        </Form.Item>
      </Form>
    </Modal>
  );
}