import { LOCALES } from '@/i18n/locales';

export default {
  [LOCALES.CHINESE]: {
    'hello': '你好',
    'name': '名字',
    'age': '年龄',
    'attr': '属性',
    'keyword': '关键字',
    'job': '工作',
    'edit': '编辑',
    'create': '创建',
    'delete': '删除',
    'deletion': '删除',
    'search': '搜索',
    'actions': '操作',
    'Please input your name': '请输入你的名字',
    'Please input your age': '请输入你的年龄',
    'test': '测试',
    'click': '点击',
    'Confirm': '确认',
    'cancel': '取消',
    'Are you sure you want to delete the item': '确认删除吗',
    'creator': '创建表单',
    'detail': '详情',
    'stuNo': '学号',
    'gender': '性别',
    'department': '公寓',
    'course': '课程',
    'Are you sure to delete': '确定删除',
    'power': '能力',
    
    
    'account':'账户信息',
    'accountIdentity':'账户所属身份',
    'accountMessage':'账户消息',
    'accountPermission':'账户权限',
    'chart':'聊天记录',
    'chartAppeal':'聊天违规申述',
    'chartAppealHanding':'聊天违规申述处理',
    'chartCorrect':'聊天违规更正',
    'chartSensitive':'聊天敏感词库',
    'chartViolation':'聊天违规',
    'chartViolationPunish':'聊天违规处罚',
    'company':'公司信息',
    'companyAnnouncement':'公司公告',
    'companyCheck':'公司审核',
    'companyComplaint':'公司投诉',
    'companyComplaintHandling':'公司投诉处理',
    'companyCooperationMedia':'公司合作媒体',
    'companyDepartment':'公司部门',
    'companyForbidden':'公司禁用',
    'companyIntroduce':'公司介绍',
    'companyJob':'公司岗位',
    'companyJobPermission':'公司岗位权限',
    'companyLicense':'公司许可证',
    'companyManager':'公司高管',
    'companyMeeting':'公司会议纪要',
    'companyNotice':'公司通知',
    'companyNoticeDetail':'公司通知明细',
    'companyPunish':'公司处罚',
    'companyRemind':'公司资质提醒',
    'companyRemindDetail':'公司提醒明细',
    'companyTeam':'公司团队',
    'customer':'客户信息',
    'customerAllocation':'客户分配',
    'customerAttention':'客户关注',
    'customerComplaint':'客户投诉',
    'customerPayment':'客户付款',
    'customerRefund':'客户退款',
    'customerRiskCheck':'客户风险审核',
    'customerServiceDetail':'客户服务记录',
    'customerSign':'客户签约',
    'customerSignCheck':'客户签约检查',
    'employee':'员工信息',
    'employeeCertificate':'员工证书',
    'employeeCheck':'员工审核',
    'employeeComplaint':'员工投诉',
    'employeeComplaintHanding':'员工投诉处理',
    'employeeExperience':'员工工作经历',
    'employeeForbidden':'员工禁用',
    'employeeIntroduce':'员工介绍',
    'employeeJob':'员工所属岗位',
    'employeePunish':'员工处罚',
    'employeeSecurityAccount':'员工证券账户',
    'employeeTeam':'员工所属团队',
    'employeeTrain':'员工培训经历',
    'identity':'身份定义',
    'identityPermission':'身份权限',
    'permission':'权限定义',
    'product':'产品信息',
    'productGrade':'产品分级',
    'productIntroduce':'产品介绍',
    'productService':'产品服务',
    'regulator':'监管账户信息',
    'regulatorOrganization':'监管组织',
    'regulatorStation':'监管职位',
    'regulatorStationPermission':'监管职位权限',
    'security':'证券信息',
    'securityInstitution':'证券公司列表',
    
    
    'accountName':'账户名',
    'accountPassword':'账户密码',
    'accountStatus':'账户状态',
    'accountType':'账户类型',
    'address':'地址',
    'announcementContent':'公告内容',
    'announcementFile':'公告文件',
    'announcementTitle':'公告标题',
    'announcementType':'公告类型',
    'appealReason':'申述理由',
    'appealResult':'申述结果',
    'appealTitle':'申述标题',
    'asset':'股票资金量',
    'beneficiary':'交易的实际受益人',
    'birthdate':'出生日期',
    'certificateAuthority':'证书颁发机构',
    'certificateFile':'证书文件',
    'certificateName':'证书名称',
    'certificateNumber':'证书编号',
    'certifier':'证明人',
    'certifierPhone':'证明人电话',
    'chargePerson':'项目负责人',
    'chartContent':'发送内容',
    'chartFile':'发送文件',
    'checkReason':'触发审核原因',
    'checkResult':'审核结果',
    'checkTitle':'检查提示标题',
    'checkType':'检查类型',
    'city':'城市',
    'companyName':'公司名称',
    'complaintAppeal':'投诉诉求',
    'complaintContent':'投诉内容',
    'complaintEmail':'投诉邮箱',
    'complaintPhone':'投诉电话',
    'complaintStatus':'处理状态',
    'complaintTitle':'投诉标题',
    'complaintWechart':'投诉微信号',
    'contractFile':'合同文件',
    'contractNumber':'合同编号',
    'contractPeriod':'合同期限',
    'cooperation':'合作内容',
    'cooperationResult':'版面安排或者节目时间段',
    'correctContent':'更正内容',
    'correctTitle':'更正标题',
    'createTime':'创建时间',
    'creditCode':'统一社会信用代码',
    'customerName':'姓名',
    'deadline':'期限',
    'departmentName':'部门名称',
    'education':'学历',
    'email':'邮箱',
    'emergencyContact':'紧急联系人',
    'emergencyPhone':'紧急联系人电话',
    'employeeName':'姓名',
    'endDate':'终止时间',
    'endTime':'终止时间',
    'examResult':'考试结果',
    'exchange':'交易所',
    'expireDay':'到期前多少天',
    'failReason':'未通过原因',
    'followUp':'后续处理措施',
    'foundDate':'成立日期',
    'handingResult':'处理结果',
    'idCard':'注册身份证号',
    'idFile':'身份证文件',
    'idPeriod':'身份证有效期',
    'identityDescription':'身份描述',
    'identityName':'身份名称',
    'institutionName':'证券公司名称',
    'institutionType':'证券公司类型',
    'integrity':'诚信记录',
    'introduceContent':'介绍内容',
    'introduceTitle':'介绍标题',
    'isAppeal':'是否申述',
    'isAppealSuccess':'是否申述成功',
    'isCorrect':'是否更正',
    'isEnsureReceive':'是否确认接收',
    'isHode':'是否持有',
    'isInvoice':'是否给发票',
    'isPractice':'是否投顾证书',
    'isReceive':'是否确认接收',
    'isSend':'是否确认发送',
    'isToCustomer':'是否发送给客户',
    'jobContent':'工作内容',
    'jobName':'岗位名称',
    'legalPerson':'法定代表人',
    'licenseFile':'许可证文件',
    'licenseNumber':'许可证编号',
    'licensePeriod':'许可证有效期',
    'managerName':'高管姓名',
    'managerPosition':'高管职位',
    'mediaName':'媒体名称',
    'mediaType':'媒体类型',
    'meetingAddress':'会议地点',
    'meetingContent':'会议内容',
    'meetingType':'会议类型',
    'messageType':'消息类型',
    'noticeContent':'通知内容',
    'noticeTitle':'通知标题',
    'onlinePosition':'上架位置',
    'operationType':'操作类型',
    'organizationName':'组织名称',
    'origin':'获客渠道',
    'partakePerson':'参与人',
    'payment':'付款金额',
    'permissionApi':'权限相关接口',
    'permissionDescription':'权限描述',
    'permissionGroup':'权限分组',
    'permissionName':'权限名',
    'permissionTable':'相关表',
    'phone':'手机号',
    'plot':'情节判断',
    'plotJudge':'情节判断',
    'price':'价格',
    'productName':'产品名称',
    'productStatus':'产品状态',
    'productType':'产品类型',
    'punishAccording':'处罚事实依据',
    'punishNumber':'处罚文号',
    'punishReason':'处罚事由',
    'punishTitle':'处罚标题',
    'punishType':'处罚内容',
    'qq':'QQ号',
    'realPerson':'实际控制投资者的自然人',
    'refund':'退款金额',
    'refundVoucher':'退款凭证',
    'region':'所在地区',
    'registerCapital':'注册资本',
    'registerOffice':'登记机关',
    'regulatorName':'姓名',
    'remark':'备注',
    'repeatDay':'重复多少天',
    'result':'处理结果',
    'resultType':'沟通结果',
    'risk':'风险偏好',
    'roleType':'通用角色类型',
    'securityAccount':'证券账户',
    'securityAccountStatus':'证券账户状态',
    'securityCode':'证券代码',
    'securityName':'证券名称',
    'securityType':'证券类型',
    'sensitiveMatch':'敏感词匹配语句',
    'sensitiveWord':'敏感词',
    'serviceCount':'服务次数',
    'serviceType':'服务类型',
    'sex':'性别',
    'signType':'签约类型',
    'sourceTable':'来源表',
    'sponsored':'推广内容',
    'startDate':'起始时间',
    'startTime':'起始时间',
    'stationName':'职位名称',
    'suggestion':'处理意见',
    'suggestionContent':'建议内容',
    'suggestionType':'建议类型',
    'teamName':'团队名',
    'trainAddress':'培训地点',
    'trainContent':'培训内容',
    'updateTime':'更新时间',
    'updateUser':'更新人员',
    'violationContent':'违规内容',
    'voucher':'付款凭证',
    'wechart':'微信号',
    'work':'职业',
    'workUnit':'工作单位',
    'xhUrl':'协会链接',
    
  }
}
